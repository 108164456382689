.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 32px;

    h1 {
        color: rgba(0, 0, 0, 0.87);
        font-size: 2em;
    }
}

.logo {
    height: 80px;
    margin: 16px;
}

.data {
    margin-top: 16px;
}
